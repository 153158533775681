import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router';
import BannerPart from '../part/BannerPart';
import TelSelectPart from '../part/TelSelectPart';
import InternetPart from '../part/InternetPart';
import MorePart from '../part/MorePart';
import NoticeBannerPart from '../part/NoticeBannerPart';
import StepPart from '../part/StepPart';
import ReviewPart from '../part/ReviewPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import PayPart from '../part/PayPart';
import { apiGetPayment, apiGetTelList, apiGetTotalResPay } from '../../../api/api_home';
import { TEL_OBJ_NUM } from '../../applicationForm/data/dummy';

export default function InternetPage() {
    const params = useParams();
    const [telList,setTelList] = useState<any>([]);
    const [selectData,setSelectData] = useState<any>({
        tel:{
            "company_id": 1,
            "company_code": "1",
            "company_name": "KT",
            "company_icon": "http://192.168.11.11:13131/company_image/kt.png",
            "company_product_codes": [
                "001"
            ]
        },
        internet: {
            "product_id": 1,
            "company_code": "1",
            "product_code": "internet-00001",
            "product_name": "슬림",
            "product_summary": "",
            "product_description": "",
            "product_max_speed": "100",
            "product_basic_price": "22000",
            "product_combine_tv_price": "22000",
            "product_router_price_gap": "1100",
            "product_combine_sale_price": "5500",
            "product_router_product_code": "[]",
            "product_require_option_code": "[]"
        },
        tv: {
            // "product_id": 15,
            // "company_code": "1",
            // "product_code": "TV-X5UUX",
            // "product_name": "지니 TV 베이직",
            // "product_channel_count": "236",
            // "product_basic_price": "12100",
            // "product_settop_price_gap": "4400",
            // "product_settop_product_code": "[]",
            // "product_combine_internet_router_price": [
            //     {
            //         "product_code": "internet-00001",
            //         "router_price": "1100"
            //     },
            //     {
            //         "product_code": "internet-00002",
            //         "router_price": "1100"
            //     },
            //     {
            //         "product_code": "internet-00003",
            //         "router_price": "0"
            //     }
            // ]
        },
        landline: {
            // "product_id": 1,
            // "company_code": "1",
            // "product_code": "landline-8szNJ",
            // "product_name": "인터넷전화(정액)",
            // "product_summary": "우리끼리3000",
            // "product_description": "망내전화 3,000분 제공",
            // "product_basic_price": "5940"
        },
        bundle:{
            // "bundle_code": "bundle-rO6KeJWGZa",
            // "bundle_price_combine": "42900",
            // "bundle_freegift_basic_price": "450000"
        },
        totalPay: 0,
        bundle_code: '',
        calc: {
            // basic_price: 22000,
            // combine_price: 18700,
            // equip_weekday_price: 36000,
            // equip_weekend_price: 45000,
            // freegift_price: 90000,
        }
    })
    const [more,setMore] = useState<any>([]);
    const [payList,setPayList] = useState<any>();
    
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    useEffect(()=>{
        getTelList();
    },[])

    useEffect(()=>{
        let tel:any = params.tel
        if(params.tel === undefined){
            tel = 'kt';
        }
        tel = TEL_OBJ_NUM[tel];
        if(telList.length === 0) return;
        getPayList(tel);
    },[params,telList]);

    useEffect(()=>{
        // 요금 결과
        totalResPay();
    },[more,selectData?.tel?.id,selectData?.internet?.product_code,selectData?.tv?.product_code,selectData?.landline?.product_code,payList])

    useEffect(()=>{
        if(more.length === 0) return;
        if(payList.length === 0) return;
        const tvChk = more.filter((item:any)=> item === 2);
        const tvOn = tvChk.length > 0;
        const phoneChk = more.filter((item:any)=> item === 3);
        const phoneOn = phoneChk.length > 0;
        if(tvOn){
            setSelectData((obj:any)=>({...obj,tv:payList.tv[0]}))
        }else{
            setSelectData((obj:any)=>({...obj,tv:{}}))
        }
        if(phoneOn){
            setSelectData((obj:any)=>({...obj,landline:payList.landline[0]}))
        }else{
            setSelectData((obj:any)=>({...obj,landline:{}}))
        }
    },[more,payList])

    // 통신사 리스트 가져오기
    const getTelList = async() =>{
        const res = await apiGetTelList('01');
        if(res.result){
            setTelList(res.data);
            setSelectData((obj:any)=>({
                ...obj,
                tel: res.data[0],
            }))
        }
    }

    // 요금제 가져오기
    const getPayList = async(tel:number) =>{
        const telObj = telList.filter((data:any)=>data.company_id === tel);
        if(telObj.length === 0) return;
        const res = await apiGetPayment(tel,'01');
        if(res.result){
            const newTvList1 = res.data.tv.sort((a:any, b:any) => {
                return Number(a.product_channel_count) - Number(b.product_channel_count);
            });
            const newTvList2 = newTvList1.sort((a:any, b:any) => {
                return Number(a.product_basic_price) - Number(b.product_basic_price);
            });
            const newData = {
                ...res.data,
                tv: newTvList2,
            }
            setPayList(newData);
            
            setSelectData((obj:any)=>({
                ...obj,
                tel: telObj[0],
                internet: newData.internet[0],
            }));
            setMore([1])
        }
    }

    // 결합 요금 계산
    const totalResPay = async() =>{
        const res = await apiGetTotalResPay(selectData.tel.company_code,selectData.internet.product_code,selectData.tv.product_code ?? '',selectData.landline.product_code ?? '', '');
        
        if(res.result){
            setSelectData((obj:any)=>({
                ...obj,
                calc: res.data.calc,
                bundle_code: res.data.info.bundle_code
            }))
        }
    }

    return (
        <Wrap>
            <TelSelectPart selectData={selectData} telList={telList}/>
            <InternetPart selectData={selectData} setSelectData={setSelectData} more={more} setMore={setMore} payList={payList} />
            <MorePart selectData={selectData} setSelectData={setSelectData} more={more} setMore={setMore} payList={payList}></MorePart>
            <NoticeBannerPart selectData={selectData} />
            <StepPart selectData={selectData} />
            <MaxWidthWrap>
                <BasicText align='center' marginTop={5} marginBottom={5} fontWeight={700} fontSize={30} mFontSize={20}>실제 고객님들의 사용 후기</BasicText>
                <ReviewPart />
            </MaxWidthWrap>
            <PayPart selectData={selectData} />
        </Wrap>
    )
}

const Wrap = styled.div`
`;

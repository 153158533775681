import React from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { calculateMonthlyPayment, discountPlanRes, numberWithComma } from '../../../../utils/format/number';
import BasicText from '../../../basic/BasicText';

export default function ResultMonthItem({buyType,payment,result,discountType}:any) {
    const buyMonth = Number(buyType ?? 0)
    const buyMonthRes = buyMonth === 0 ? 1 : buyMonth;
    const monthPay = Math.floor(result/buyMonthRes);
    const monthTotalPay = buyMonth === 0 ? 0 : calculateMonthlyPayment(result,buyMonthRes);
    const planPrice = discountType === '1' ? (payment?.plan_price ?? 0) : discountPlanRes(payment?.plan_price ?? 0)
    return (
        <Wrap>
            <InnerWrap>
                <TitleWrap>
                    <TitleItem>{buyMonth === 0 ? '일시불':`월 할부금\n(${buyMonth} 개월)`}</TitleItem>
                    <TitleItem>+</TitleItem>
                    <TitleItem>요금제<br/>({payment?.plan_name})</TitleItem>
                    <TitleItem>+</TitleItem>
                    <TitleItem>월 납부액<br/>(부가세 포함)</TitleItem>
                </TitleWrap>
                <ValueWrap>
                    <ValueItem>{numberWithComma(monthPay)}원</ValueItem>
                    <ValueItem>+</ValueItem>
                    <ValueItem>{numberWithComma(planPrice)}원</ValueItem>
                    <ValueItem>=</ValueItem>
                    <ValueItem>{numberWithComma(monthTotalPay + planPrice)}원</ValueItem>
                </ValueWrap>
            </InnerWrap>
            {monthTotalPay === 0 ?
                <BasicText marginTop={2}>※ 일시불 구매시 할부이자가 없습니다.</BasicText> :
                <BasicText marginTop={2}>※ 할부이자는 할부원금 기준 5.9% {numberWithComma(monthTotalPay-monthPay)}원 별도 포함</BasicText>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
   
`;

const InnerWrap = styled.div`
    margin-top: 15px;
    border: 1px solid #d2d2d2;
`;

const TitleWrap = styled(RowBetween)`
    background-color: #232323;
    padding: 8px 15px;
`;

const TitleItem = styled(Center)`
    color: #fff;
    text-align: center;
    width: calc(20% + 15px);
    flex-shrink: 0;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;
    white-space: pre-wrap;
    &:nth-of-type(2n){
        width: calc(20% - 30px);
    }
    @media ${addTheme.media.s}{
        font-size: 14px;
    }
`;

const ValueWrap = styled(RowBetween)`
    background-color: #fff;
    padding: 15px;
`;

const ValueItem = styled(TitleItem)`
    color: #000;
`;
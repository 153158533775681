import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷 단독', channel:0, M100:{pay0:20790,pay1:16630,gift:13},M160:{pay0:25410,pay1:20320,gift:13}, M500:{pay0:29260,pay1:23400,gift:18}, G1:{pay0:30800,pay1:24640,gift:18}},
    {name: '인터넷 + UHD 이코노미', channel:109, M100:{pay0:29530,pay1:25370,gift:30},M160:{pay0:33160,pay1:28080,gift:30}, M500:{pay0:36190,pay1:30330,gift:35}, G1:{pay0:37400,pay1:31240,gift:40}},
    {name: '인터넷 + UHD 뉴베이직', channel:245, M100:{pay0:31730,pay1:27570,gift:30},M160:{pay0:35360,pay1:30280,gift:30}, M500:{pay0:38390,pay1:32530,gift:35}, G1:{pay0:39600,pay1:33400,gift:40}},
    {name: '인터넷 + UHD 뉴프리미엄', channel:245, M100:{pay0:33930,pay1:29770,gift:30},M160:{pay0:37560,pay1:32480,gift:30}, M500:{pay0:40590,pay1:34730,gift:35}, G1:{pay0:41800,pay1:35640,gift:40}},
    {name: '인터넷 단독 + UHD 뉴베이직 + 디즈니스탠다드', channel:248, M100:{pay0:39830,pay1:35670,gift:30},M160:{pay0:43460,pay1:38380,gift:30}, M500:{pay0:46490,pay1:40630,gift:35}, G1:{pay0:47700,pay1:41540,gift:40}},
    {name: '인터넷 단독 + UHD 뉴베이직 + 디즈니프리미엄', channel:248, M100:{pay0:43830,pay1:39670,gift:30},M160:{pay0:47460,pay1:43380,gift:30}, M500:{pay0:50490,pay1:44630,gift:35}, G1:{pay0:51700,pay1:45540,gift:40}},
    {name: '인터넷 + 프로라이트', channel:218, M100:{pay0:'-',pay1:'-',gift:'-'},M160:{pay0:37560,pay1:32480,gift:30}, M500:{pay0:40590,pay1:34730,gift:35}, G1:{pay0:41800,pay1:35640,gift:40}},
    {name: '인터넷 + 프로맥스', channel:246, M100:{pay0:'-',pay1:'-',gift:'-'},M160:{pay0:39760,pay1:34680,gift:30}, M500:{pay0:42790,pay1:36930,gift:35}, G1:{pay0:44000,pay1:37840,gift:40}},
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [
        {product: '신혼플러스', terms: '예비 부부, 신혼 부부 5g: 85요금제 LTE 78 요금제 이상', benefit: '모바일 요금 6개월 무료 + 월 최대 22,850원 할인'},
        {product: '펫플러스', terms: '스마트홈', benefit: '공기청정기 렌탈료 최대 21,000원 할인 구매 시 약 23만원 할인'},
        {product: '시니어 플러스', terms: '약 65세 이상 사용자', benefit: '인터넷 3,100원 추가 할인'},
    ] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '투게더 결합', terms: '모바일 5회선, 인터넷 5회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 11,000원/ 휴대폰 최대 20,000원 할인'},
        {product: '참 쉬운 가족결합', terms: '모바일 10회선,인터넷 3회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 13,200원/ 휴대폰 최대 5,500원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: '롯데카드	', list: [
        {name: 'SK브로드밴드 B롯데카드', benefit: ['50만원 이상 실적 10,000원 할인','100만원 이상 실적 20,000원 할인']},
    ]},
    {name: '삼성카드', list: [
        {name: 'SK브로드밴드 삼성카드', benefit: ['30만원 이상 실적 7,000원 할인','70만원 이상 실적 10,000원 할인','120만원 이상 실적 13,000원 할인']},
    ]},
    
]

export default function SKBStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`SKB(알뜰) 요금표 한눈에 보기!`} />
            <TextRowWrap gap={8} margin='50px 0 0'>
                <FaCheckCircle color='#ff7500' size={22} />
                <BasicText fontSize={17} fontWeight={700}>Btv cable 요금표 한눈에 보기!</BasicText>
            </TextRowWrap>
            <TableWrap>
                <TableWrapItem margin='10px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2}>인터넷 상품<p>(공유기 임대료 미포함)<br/>요금제에 따라 월<br/>1,100원~2,200원 발생</p></th>
                            <th className='title-bg' rowSpan={2}>모바일 결합 선납 여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowSpan={4}>
                                인터넷 + TV 알뜰 200<br/>
                                <span>224채널</span>
                                <p>*단일</p>
                            </th>
                            <td>요즘 우리집 결합 (홈결합)</td>
                            <td>26,400원</td>
                            <td className='gift'>22만원</td>
                            <td>34,100원</td>
                            <td className='gift'>34만원</td>
                        </tr>
                        <tr>
                            <td>요즘 우리집 결합<span className='green'>+선납</span></td>
                            <td>15,400원</td>
                            <td className='gift'>-</td>
                            <td>23,100원</td>
                            <td className='gift'>-</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합 (휴대폰 결합)</td>
                            <td>24,200원</td>
                            <td className='gift'>22만원</td>
                            <td>28,600원</td>
                            <td className='gift'>34만원</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합<span className='green'>+선납</span></td>
                            <td>13,200원</td>
                            <td className='gift'>-</td>
                            <td>17,600원</td>
                            <td className='gift'>-</td>
                        </tr>
                        <tr className="title-bg">
                            <th rowSpan={2}>TV 추가</th>
                            <td>TV 상품</td>
                            <td>알뜰 200</td>
                            <td colSpan={3} rowSpan={2}>
                                <span className="text-green">선납요금제</span>
                                (11,000원)는<br/> 3년 약정 만료 후 요금 <strong>할인 해제</strong>
                            </td>
                        </tr>
                        <tr className="title-bg">
                            <td>TV 요금제</td>
                            <td>9,350원</td>
                        </tr>
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <TextRowWrap gap={8} margin='30px 0 0'>
                <FaCheckCircle color='#ff7500' size={22} />
                <BasicText fontSize={17} fontWeight={700}>Btv POP 요금표 한눈에 보기!</BasicText>
            </TextRowWrap>
            <TableWrap>
                <TableWrapItem margin='10px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2}>인터넷 상품<p>(공유기 임대료 미포함)<br/>요금제에 따라 월<br/>1,100원~2,200원 발생</p></th>
                            <th className='title-bg' rowSpan={2}>모바일 결합 선납 여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowSpan={4}>
                                인터넷 + Btv pop 180<br/>
                                <span>186채널</span>
                            </th>
                            <td>요즘 우리집 결합 (홈결합)</td>
                            <td>27,500원</td>
                            <td className='gift'>30만원</td>
                            <td>35,200원</td>
                            <td className='gift'>35만원</td>
                            <td>40,700원</td>
                            <td className='gift'>35만원</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합 (휴대폰 결합)</td>
                            <td>25,300원</td>
                            <td className='gift'>30만원</td>
                            <td>29,700원</td>
                            <td className='gift'>35만원</td>
                            <td>33,000원</td>
                            <td className='gift'>35만원</td>
                        </tr>
                        <tr>
                            <td>요즘 우리집 결합<span className='green'>선납</span></td>
                            <td>16,500원</td>
                            <td className='gift'>10만원</td>
                            <td>24,200원</td>
                            <td className='gift'>10만원</td>
                            <td>29,700원</td>
                            <td className='gift'>10만원</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합<span className='green'>선납</span></td>
                            <td>14,300원</td>
                            <td className='gift'>10만원</td>
                            <td>18,700원</td>
                            <td className='gift'>10만원</td>
                            <td>22,000원</td>
                            <td className='gift'>10만원</td>
                        </tr>
                        <tr>
                            <th rowSpan={4}>
                                인터넷 + Btv pop 230<br/>
                                <span>231채널</span>
                            </th>
                            <td>요즘 우리집 결합 (홈결합)</td>
                            <td>29,700원</td>
                            <td className='gift'>30만원</td>
                            <td>37,400원</td>
                            <td className='gift'>35만원</td>
                            <td>42,900원</td>
                            <td className='gift'>35만원</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합 (휴대폰 결합)</td>
                            <td>27,500원</td>
                            <td className='gift'>30만원</td>
                            <td>31,900원</td>
                            <td className='gift'>35만원</td>
                            <td>35,200원</td>
                            <td className='gift'>35만원</td>
                        </tr>
                        <tr>
                            <td>요즘 우리집 결합<span className='green'>선납</span></td>
                            <td>18,700원</td>
                            <td className='gift'>10만원</td>
                            <td>26,400원</td>
                            <td className='gift'>10만원</td>
                            <td>31,900원</td>
                            <td className='gift'>10만원</td>
                        </tr>
                        <tr>
                            <td>요즘 가족 결합<span className='green'>선납</span></td>
                            <td>16,500원</td>
                            <td className='gift'>10만원</td>
                            <td>20,900원</td>
                            <td className='gift'>10만원</td>
                            <td>24,200원</td>
                            <td className='gift'>10만원</td>
                        </tr>
                        <tr className="title-bg">
                            <th rowSpan={2}>TV 추가</th>
                            <td>TV 상품</td>
                            <td colSpan={2}>Btv pop 180</td>
                            <td colSpan={2}>Btv pop 230</td>
                            <td colSpan={2} rowSpan={2}>
                                <span className="text-green">선납요금제</span>는<br/>3년 약정 만료 후 요금 <strong>할인 해제</strong>
                            </td>
                        </tr>
                        <tr className="title-bg">
                            <td>TV 요금제</td>
                            <td colSpan={2}>5,500원</td>
                            <td colSpan={2}>6,600원</td>
                        </tr>
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <BasicText marginTop={2.5}><strong>가족 중 SK 휴대폰 이용자가 없다면?</strong><br/>-&gt; 요즘 우리집 결합</BasicText>
            <BasicText marginTop={2.5}><strong>가족 중 SK 휴대폰 이용자가 1인 이상이라면?</strong><br/>-&gt; 요즘 가족 결합</BasicText>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'할인혜택 모르면 나만 손해'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='결합할인은 필수' />
                    <BasicText>인터넷 가입 시 결합할인을 생각하지 않는 건,<br/>돈을 버린다는 의미와 같습니다.</BasicText>
                    <BasicText>반드시 확인하여 최저요금에 도전해봅시다!<br/>휴대폰 결합 시, 휴대폰 요금이 할인되니 참고해주세요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="16%" />
                                <col width="21%" />
                                <col width="21%" />
                                <col width="21%" />
                                <col width="21%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>휴대폰 결합 대수</th>
                                    <th>인터넷 100메가</th>
                                    <th>인터넷 500메가</th>
                                    <th>인터넷 1기가</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowSpan={4}>인터넷 + 휴대폰</th>
                                    <td>1회선 결합</td>
                                    <td>3,500원 할인</td>
                                    <td>3,500원 할인</td>
                                    <td>3,500원 할인</td>
                                </tr>
                                <tr>
                                    <td>2회선 결합</td>
                                    <td>7,000원 할인</td>
                                    <td>7,000원 할인</td>
                                    <td>7,000원 할인</td>
                                </tr>
                                <tr>
                                    <td>3회선 결합</td>
                                    <td>18,000원 할인</td>
                                    <td>18,000원 할인</td>
                                    <td>18,000원 할인</td>
                                </tr>
                                <tr>
                                    <td>4회선 결합</td>
                                    <td>18,000원 할인</td>
                                    <td>24,000원 할인</td>
                                    <td>24,000원 할인</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2.5}>※ 인터넷 가입 명의자와 휴대폰 명의자가 동일해야 결합이 가능합니다.</BasicText>
                    <BasicText>※ 휴대폰 2회선 이상 결합하는 경우 인터넷 가입 명의자의 휴대폰이 있어야 결합이 가능하니 꼭 참고해주세요!</BasicText>
                    <BasicText marginTop={2.5}>혹시 어려우시다면 언제든 문의 주세요.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금 자동이체 시 할인혜택이 적용됩니다.<br/>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan={2}>롯데카드</td>
                                    <td rowSpan={2}>SK브로드밴드 B롯데카드</td>
                                    <td>50만원 이상 실적 10,000원 할인</td>
                                </tr>
                                <tr>
                                    <td>100만원 이상 실적 20,000원 할인</td>
                                </tr>
                                <tr>
                                    <td rowSpan={3}>삼성카드</td>
                                    <td rowSpan={3}>SK브로드밴드 삼성카드</td>
                                    <td>30만원 이상 실적 7,000원 할인</td>
                                </tr>
                                <tr>
                                    <td>70만원 이상 실적 10,000원 할인</td>
                                </tr>
                                <tr>
                                    <td>120만원 이상 실적 13,000원 할인</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'인터넷 전화도 필요하시다면'} />
                <SectionWrap padding='40px 0 0'>
                    <BasicText marginTop={0.5}>혹시 집이나 매장에서 쓸 전화기도 함께 필요하신가요?<br/>그렇다면 인터넷선만 있으면 되는 인터넷 전화를 추천드립니다.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="17%" />
                                <col width="28%" />
                                <col width="26%" />
                                <col width="29%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>요금제</th>
                                    <th>월 요금</th>
                                    <th>전화 단말기</th>
                                    <th>설치비</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>집전화 프리 5000</td>
                                    <td>3년 약정 전화 단독 7,700원<br/>3년 약정 인터넷 결합 시 4,400원</td>
                                    <td>직접 준비</td>
                                    <td>인터넷과 함께 설치 시<br/>6,600원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='04' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전 대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>저희 <span>굿폰몰</span>에게 문의주시면 확인해서 알려드리겠습니다^^</BasicText>
                    <BasicText marginTop={2} >(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2} >또한 SKB(알뜰)은 한정된 지역에서만 설치가 가능하기 때문에 반드시 확인해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>설치 가능 지역</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0' className='table-map'>
                            <colgroup>
                                <col width="10%" />
                                <col width="45%" />
                                <col width="55%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>서울</th>
                                    <td> 강서구, 광진구, 성동구, 노원구, 도봉구, 강북구, 동대문구, 서대문구, 종로구, 중구 </td>
                                    <td rowSpan={11}><img loading="lazy" src="https://cdn.ajd.kr/images/platform/internet/must-read/btv_map.jpg?w=306&amp;h=355&amp;q=100&amp;f=webp" alt="전국 지도"/></td>
                                </tr>
                                <tr>
                                    <th>인천</th>
                                    <td>남동구, 서구, 중구, 동구, 강화군, 울진군</td>
                                </tr>
                                <tr>
                                    <th>경기</th>
                                    <td> 용인, 이천, 평택, 안성, 수원, 오산, 화성, 과천, 의왕, 안양, 군포, 광명, 시흥, 안산 </td>
                                </tr>
                                <tr>
                                    <th>충남</th>
                                    <td>세종, 천안, 아산</td>
                                </tr>
                                <tr>
                                    <th>대구</th>
                                    <td>서구, 중구, 남구, 달서구, 달성군</td>
                                </tr>
                                <tr>
                                    <th>부산</th>
                                    <td>강서구, 북구, 사상구, 남구, 수영구, 서구, 사하구</td>
                                </tr>
                                <tr>
                                    <th>전북</th>
                                    <td>전주, 무주, 진안, 장수, 완주</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5}>굿폰몰에 명시된 요금은 <strong>셋톱박스, 모뎀 임대료 및 부가가치세가</strong>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며 약정 후 꼭 반납을 해야합니다.<br/>혹시나 잃어버리지 않게 주의하세요!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>UHD 셋톱박스</td>
                                    <td>4,400원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>TV 채널 편성이 궁금하다면 <strong><a style={{display:'inline-block'}} href="https://www.bworld.co.kr/content/realtime/realtime_list.do?menu_id=P03030100" target="_blank" rel="noopener noreferrer">여기</a></strong>를 클릭해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <BasicText marginTop={0.5}>Btv POP은 스마트3 셋톱박스 제공으로<br/>넷플릭스를 제외한 나머지 OTT서비스는 시청할 수 있습니다.</BasicText>
                    <BasicText marginTop={2}>아쉽게도 Btv cable은 OTT 시청이 불가해요.<br/>크롬캐스트, 미러링 등을 통해 시청할 수 있습니다.</BasicText>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='05' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} spColor={addTheme.color}>*<span>굿폰몰</span>는 설치 후 9개월 사용으로 사은품 반환의무가 없어진답니다.<br/>(타사는 대부분 12개월)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>CABLE TV단독</th>
                                    <th>POP TV 단독</th>
                                    <th>인터넷 + TV</th>
                                    <th>CABLE TV추가<br/>(1대당)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>평일</th>
                                    <td>34,100원</td>
                                    <td>34,100원</td>
                                    <td>56,100원</td>
                                    <td>13,200원</td>
                                </tr>
                                <tr>
                                    <th>주말</th>
                                    <td>40,920원</td>
                                    <td>40,920원</td>
                                    <td>70,125원</td>
                                    <td>17,600원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생하며, 이사를 한다면 이전설치비가 청구됩니다(비용동일)</BasicText>
                    </TextRowWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>야간,주말,공휴일에 설치 시 추가 할증이 발생됩니다.</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;
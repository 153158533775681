import styled from '@emotion/styled'
import React, { useState } from 'react'
import BasicText from '../../../basic/BasicText';
import { BtnItem, ContentWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { onlyNumber } from '../../../../utils/format/string';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import OpenPlacePart from '../../../applicationForm/part/OpenPlacePart';
import RegisterPayPart from './RegisterPayPart';
import { TERMS_LIST } from '../../../../data/data';
import isEmail from 'validator/lib/isEmail';
import { apiSetOrder } from '../../../../api/api_phone';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { globalRegisterInit } from '../../../../store/common/globalRegister';

const USER_TYPE = [
    {id:1,name:'성인일반'},
    {id:2,name:'청소년'},
]

const TermsItem = ({data,chkAction}:any) =>{
    const [open,setOpen] = useState(false);
   
    return(
        <TermsBigWrap>
            <TermsItemWrap>
                <TermsTextWrap>
                    <BasicText fontSize={17}>{data.name}</BasicText>
                    <BasicText fontSize={15} color='#9b9b9b' deco='underline' onClick={()=>setOpen(!open)}>자세히 보기</BasicText>
                </TermsTextWrap>
                <TermsInputWrap>
                    <input type='checkbox' checked={data.state} onChange={(e:any)=>chkAction(e.target.checked,data.id)}></input>
                    동의
                </TermsInputWrap>
            </TermsItemWrap>
            {open &&
                <BasicText marginTop={1} wh='pre-wrap'>{data.content}</BasicText>
            }
        </TermsBigWrap>
    )
}

export default function RegisterInputPart({data,monthResult,monthPrice}:any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name,setName] = useState('');
    const [birth,setBirth] = useState('');
    const [userType,setUserType] = useState('1');
    const [registerPhone,setRegisterPhone] = useState('');
    const [addPhone,setAddPhone] = useState('');
    const [email,setEmail] = useState('');
    const [pw,setPw] = useState('');

    const [p_name,setP_name] = useState('');
    const [p_birth,setP_birth] = useState('');
    const [p_phone,setP_phone] = useState('');
    const [p_type,setP_type] = useState('');

    const [placeOpen,setPlaceOpen] = useState(false);
    const [place,setPlace] = useState<any>();
    const [placeAdd,setPlaceAdd] = useState('');
 
    const [userPlz,setUserPlz] = useState('');

    const [termsList,setTermsList] = useState(TERMS_LIST);
    const chkAction = (chk:any,id:any) =>{
        setTermsList((arr:any)=>arr.map((item:any)=>{
            return item.id === id ? {...item,state:chk}:item
        }))
    }

    const btnAction = async() =>{
        if(data === undefined) return;
        const termsResult = termsList.filter((data:any) => data.state === false);
        if(termsResult.length > 0){
            alert('약관을 동의해주세요.');
            return;
        }
        if(name === '' || birth === '' || registerPhone === '' || addPhone === '' || email === '' || pw === ''){
            alert('가입자 정보를 입력해주세요.');
            return;
        }
        if(birth.length !== 8){
            alert('생년월일은 19990101 형식의 8자리를 입력해주세요.');
            return;
        }
        if(registerPhone.length < 10){
            alert('개통할 휴대전화번호를 확인해주세요.');
            return;
        }
        if(addPhone.length < 10){
            alert('추가 연락처를 확인해주세요.');
            return;
        }
        if(!isEmail(email)){
            alert('이메일 형식을 확인해주세요.');
            return;
        }
        if(userType === '2'){
            if(p_name === '' || p_birth === '' || p_phone === '' || p_type === ''){
                alert('법정대리인 정보를 입력해주세요.');
                return;
            }
        }
        if(place === undefined){
            alert('주소를 입력해주세요.');
            return;
        }
        
        const res = await apiSetOrder(data.plan.discount_id,data.size.volume_id,data.color.color_id,data.discount_type,data.buyType,data.card?.item?.dis_card_id ?? '0',userPlz,name,birth,userType,addPhone,email,pw,place?.zonecode,place?.roadAddress,placeAdd,p_name,p_birth,p_phone,p_type,registerPhone,data.telco_type);
        if(res.result){
            alert('신청을 했습니다.');
            navigate('/registerDone');
            dispatch(globalRegisterInit());
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <WrapItem>
                <RegisterPayPart data={data} monthResult={monthResult} monthPrice={monthPrice} />
                <div style={{marginTop:'100px'}}>                
                    <BasicText fontSize={20} fontWeight={700}>가입자 정보</BasicText>
                    <ContentWrap mt={20} gap={12}>
                        <ItemWrap>
                            <BasicText fontSize={17}>이름</BasicText>
                            <ItemInputWrap>
                                <input value={name} onChange={(e:any)=>setName(e.target.value)} placeholder='ex) 홍길동' maxLength={20}></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText fontSize={17}>생년월일</BasicText>
                            <ItemInputWrap>
                                <input value={birth} onChange={(e:any)=>setBirth(onlyNumber(e))} placeholder='ex) 19990104 (숫자 8자리)' maxLength={8}></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText fontSize={17}>고객 유형</BasicText>
                            <ItemInputWrap style={{padding:0}}>
                                <SelectItem value={Number(userType)} setValue={setUserType} none={'none'} width='100%'>
                                    {USER_TYPE.map((data,index)=>{
                                        return(
                                            <MenuItem key={`cate-${index}`} value={data.id}>{data.name}</MenuItem>
                                        )
                                    })}
                                </SelectItem>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText fontSize={17}>개통할 휴대전화번호</BasicText>
                            <ItemInputWrap>
                                <input value={registerPhone} onChange={(e:any)=>setRegisterPhone(onlyNumber(e))} placeholder='ex) 01012345678' maxLength={14}></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText fontSize={17}>추가 연락처</BasicText>
                            <ItemInputWrap>
                                <input value={addPhone} onChange={(e:any)=>setAddPhone(onlyNumber(e))} placeholder='ex) 01012345678 ' maxLength={14}></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap align='start'>
                            <BasicText fontSize={17}>이메일</BasicText>
                            <div style={{width:'100%'}}>
                                <ItemInputWrap>
                                    <input value={email} onChange={(e:any)=>setEmail(e.target.value)} placeholder='ex) iamcompany@iam.co.kr' maxLength={40}></input>
                                </ItemInputWrap>
                                <BasicText fontSize={15} marginLeft={0.5} marginTop={0.5}>이메일로 공식 신청서를 전송해드립니다.</BasicText>
                                <BasicText fontSize={15} marginLeft={0.5}>수신가능한 이메일 주소를 입력해주세요.</BasicText>
                            </div>
                        </ItemWrap>
                        <ItemWrap align='start'>
                            <BasicText fontSize={17}>비밀번호</BasicText>
                            <div style={{width:'100%'}}>
                                <ItemInputWrap>
                                    <input type='password' value={pw} onChange={(e:any)=>setPw(e.target.value)} placeholder='' maxLength={25}></input>
                                </ItemInputWrap>
                                <BasicText fontSize={15} marginLeft={0.5} marginTop={0.5}>주문 확인 시 입력할 비밀번호를 입력하세요.</BasicText>
                            </div>
                        </ItemWrap>
                    </ContentWrap>
                    {userType == '2' &&
                        <InnerWrap>
                            <BasicText fontSize={20} fontWeight={700}>법정대리인 정보</BasicText>
                            <ContentWrap mt={20} gap={12}>
                                <ItemWrap>
                                    <BasicText fontSize={17}>법정대리인 이름</BasicText>
                                    <ItemInputWrap>
                                        <input value={p_name} onChange={(e:any)=>setP_name(e.target.value)} placeholder='ex) 홍길동' maxLength={15}></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText fontSize={17}>법정대리인 생년월일</BasicText>
                                    <ItemInputWrap>
                                        <input value={p_birth} onChange={(e:any)=>setP_birth(onlyNumber(e))} placeholder='ex) 19990101' maxLength={13}></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText fontSize={17}>법정대리인 연락처</BasicText>
                                    <ItemInputWrap>
                                        <input value={p_phone} onChange={(e:any)=>setP_phone(onlyNumber(e))} placeholder='ex) 01012345678' maxLength={13}></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText fontSize={17}>법정대리인 관계</BasicText>
                                    <ItemInputWrap>
                                        <input value={p_type} onChange={(e:any)=>setP_type(e.target.value)} placeholder='' maxLength={6}></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                            </ContentWrap>
                        </InnerWrap>
                    }
                    <InnerWrap>
                        <BasicText fontSize={20} fontWeight={700}>배송지 정보</BasicText>
                        <ContentWrap mt={20} gap={12}>
                            <ItemWrap align='start'>
                                <BasicText fontSize={17}>주소</BasicText>
                                <AddressWrap>
                                    <AddressBtnWrap>
                                        <input value={place?.zonecode ?? ''} readOnly onClick={()=>setPlaceOpen(true)}></input>
                                        <BtnItem bg='#909090' padding='14px' onClick={()=>setPlaceOpen(true)}>우편번호</BtnItem>
                                    </AddressBtnWrap>
                                    <ItemInputWrap>
                                        <input value={place?.roadAddress ?? '' } readOnly></input>
                                    </ItemInputWrap>
                                    <ItemInputWrap>
                                        <input value={placeAdd} onChange={(e:any)=>setPlaceAdd(e.target.value)}></input>
                                    </ItemInputWrap>
                                </AddressWrap>
                            </ItemWrap>
                        </ContentWrap>
                    </InnerWrap>
                    <InnerWrap>
                        <BasicText fontSize={20} fontWeight={700}>요청사항</BasicText>
                        <ContentWrap mt={20} gap={12}>
                            <ItemWrap align='start'>
                                <BasicText fontSize={17}>요청사항</BasicText>
                                <ItemInputWrap>
                                    <textarea value={userPlz} onChange={(e:any)=>setUserPlz(e.target.value)} rows={4} />
                                </ItemInputWrap>
                            </ItemWrap>
                        </ContentWrap>
                    </InnerWrap>
                    <InnerWrap>
                        <BasicText fontSize={20} fontWeight={700}>약관 및 동의</BasicText>
                        <ContentWrap mt={20} gap={15}>
                            {termsList === undefined || termsList.length === 0 ?
                                <></> :
                                termsList.map((data:any,index:any)=>{
                                    return(
                                        <TermsItem key={`terms-${index}`} data={data} chkAction={chkAction} />
                                    )
                                })
                            }
                        </ContentWrap>
                    </InnerWrap>
                </div>
            </WrapItem>
            <BtnWrap onClick={btnAction}>신청하기</BtnWrap>
            <OpenPlacePart open={placeOpen} setOpen={setPlaceOpen} setPlace={setPlace} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const WrapItem = styled.div`
    border-left: 1px solid #dfdfdf;
    padding: 10px 20px;
    @media ${addTheme.media.m}{
        border: none;
        padding: 0;
    }
`;
const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const InnerWrap = styled.div`
    margin-top: 60px;
`;

const AddressWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

const AddressBtnWrap = styled(Row)`
    gap: 10px;
    flex-wrap: wrap;
    &>input{
        width: auto !important;
        border: 1px solid #e6e6e6;
        padding: 10px 14px;
        border-radius: 6px;
    }
`;
const TermsBigWrap = styled.div``;
const TermsItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 10px 0;
`;
const TermsTextWrap = styled(Row)`
    width: auto;
    gap: 0 15px;
    flex-wrap: wrap;
    &>p:nth-last-of-type(1){
        cursor: pointer;
    }
`;

const TermsInputWrap = styled.label`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    &>input{
        width: 16px;
        height: 16px;
    }
`;

const BtnWrap = styled.button`
    width: calc(100% - 20px);
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin: 30px auto 0;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;
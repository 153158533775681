import { axiosConfigInstance } from "./config";

// 상품 리스트
// 타입 'internet'|'tv'|'landline'|'cctv'
export const apiAdmGetProductList = async(token:string,admin_id:any,type:string,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/product/${type}`,token).post('',{
            method: 'getAdminProductList',
            params:{
                admin_id,
                page,
                count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 디테일
export const apiAdmGetProductDetail = async(token:string,admin_id:any,type:string,product_code:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/product/${type}`,token).post('',{
            method: 'getAdminProductDetailInfo',
            params:{
                admin_id,
                product_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 기본데이터 비/활성/삭제
export const STATUS_CHANGE:any = {
    '0': {
        res:'1',
        resTxt: '활성화'
    },
    '1': {
        res:'0',
        resTxt: '비활성화'
    },
    '2': {
        res:'2',
        resTxt: '삭제'
    },
}
// 기본데이터 삭제
export const apiAdmSetProductStatus = async(token:string,admin_id:any,type:string,product_code:string,status: '0'|'1'|'2') =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/product/${type}`,token).post('',{
            method: 'adminProductChangeStatus',
            params: {
                admin_id,product_code,
                status: STATUS_CHANGE[status].res,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 기본데이터 - 인터넷 수정/추가
export const apiAdmSetProductInternetUpdate = async(token:string,admin_id:any,method:'adminProductAdd'|'adminProductInfoEdit',info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/internet',token).post('',{
            method: method,
            params: {
                admin_id,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
// 기본데이터 - tv 수정/추가
export const apiAdmSetProductTvUpdate = async(token:string,admin_id:any,method:'adminProductAdd'|'adminProductInfoEdit',info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/tv',token).post('',{
            method: method,
            params: {
                admin_id,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 기본데이터 - 전화 수정/추가
export const apiAdmSetProductPhoneUpdate = async(token:string,admin_id:any,method:'adminProductAdd'|'adminProductInfoEdit',info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/landline',token).post('',{
            method: method,
            params: {
                admin_id,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 기본데이터 - cctv 수정/추가
export const apiAdmSetProductCctvUpdate = async(token:string,admin_id:any,method:'adminProductAdd'|'adminProductInfoEdit',info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/cctv',token).post('',{
            method: method,
            params: {
                admin_id,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들추가 - 홈
export const apiAdmSetBundleHomeAdd = async(token:string,admin_id:any,info:any,bundle_type:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/bundle',token).post('',{
            method: 'adminBundleAdd',
            params: {
                admin_id,
                bundle_type,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들리스트
export const apiAdmGetBundleList = async(token:string,admin_id:any,page:any,count:any,bundle_type:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/bundle`,token).post('',{
            method: 'getAdminBundleList',
            params:{
                admin_id,
                page,
                count,
                bundle_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들 디테일
export const apiAdmGetBundleDetail = async(token:string,admin_id:any,bundle_code:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/bundle`,token).post('',{
            method: 'getAdminBundleDetailInfo',
            params:{
                admin_id,
                bundle_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들 수정
export const apiAdmSetBundleUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/bundle`,token).post('',{
            method: 'adminBundleModify',
            params:{
                admin_id,
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들 비/활성
// status 1 활성화 / 2 비활성화
export const apiAdmSetBundleStatusUpdate = async(token:string,admin_id:any,bundle_code:any,status:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/bundle`,token).post('',{
            method: 'adminBundleChangeStatus',
            params:{
                admin_id,
                bundle_code,
                status,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 번들 삭제
export const apiAdmSetBundleDel = async(token:string,admin_id:any,bundle_code:any) =>{
    try {
        const res = await axiosConfigInstance(`/api/admin/bundle`,token).post('',{
            method: 'adminBundleDelete',
            params:{
                admin_id,
                bundle_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
/////////////////////////////////////////////////////////////////////////////
///////////// 핸드폰
// 관리자 상품 리스트
export const apiAdmGetPhProduct = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'getAdminProductList',
            params:{
                admin_id,page,count
            }
        })
        
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 디테일
export const apiAdmGetPhProductDetail = async(token:string,admin_id:any,phone_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'getAdminProductDetailInfo',
            params:{
                admin_id,phone_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 활성 / 비활성
// status : 1 활성 / 0 비활성
export const apiAdmSetPhProductState = async(token:string,admin_id:any,phone_id:any,status:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductChangeStatus',
            params:{
                admin_id,phone_id,status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 용량 리스트
export const apiAdmGetPhProductSize = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'getAdminProductVolumeList',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 색별 이미지 등록/수정하기
export const apiAdmSetPhProductColorImgUpdate = async(token:string,admin_id:any,phone_id:any,color_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductImageChange',
            params:{
                admin_id,phone_id,color_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 썸네일 등록/수정하기
export const apiAdmSetPhProductImgUpdate = async(token:string,admin_id:any,phone_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductThumbnailImageChange',
            params:{
                admin_id,phone_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 설명 이미지 등록/수정
export const apiAdmSetPhProductContentImgUpdate = async(token:string,admin_id:any,phone_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductDescriptionImageChange',
            params:{
                admin_id,phone_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 수정
export const apiAdmSetPhProductUpdate = async({token,admin_id,phone_id,phone_name,phone_model,phone_price,phone_description,discount_price,phone_im_discount}:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductInfoEdit',
            params:{
                admin_id,phone_id,phone_name,phone_model,phone_price,phone_description,discount_price,phone_im_discount
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 추가
export const apiAdmSetPhProductAdd = async(token:string,admin_id:any,data:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone',token).post('',{
            method: 'adminProductAdd',
            params: {
                admin_id,
                ...data,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 사전예약 - 0 사전예약x, 1 사전예약
export const apiAmdSetPhProductReservation = async(token:string,admin_id:any,phone_id:any,reverse_status:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/phone/',token).post('',{
            method: 'adminProductReverseStatusChange',
            params: {
                admin_id,phone_id,reverse_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

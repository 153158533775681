import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import OpenPlacePart from './OpenPlacePart';

export default function InputInstallPart({order_orderer,valueChange}:any) {
    const [placeOpen,setPlaceOpen] = useState(false);
    const setPlace = (data:any) =>{
        if(data?.zonecode === undefined || data?.zonecode === '') return;
        valueChange('orderer','order_orderer_post_code',data.zonecode);
        valueChange('orderer','order_orderer_address',data.roadAddress);
    }

    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>설치 주소 정보</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <TitleItem>주소</TitleItem>
                    <ItemInnerWrap>
                        <Row style={{gap:'20px'}}>
                            <InputItem w='160px' onClick={()=>setPlaceOpen(true)}>
                                <input required readOnly value={order_orderer?.order_orderer_post_code ?? ''}></input>
                            </InputItem>
                            <BtnItem onClick={()=>setPlaceOpen(true)}>우편번호</BtnItem>
                        </Row>
                        <InputItem>
                            <input required readOnly placeholder='기본 주소' value={order_orderer?.order_orderer_address ?? ''}></input>
                        </InputItem>
                        <InputItem>
                            <input value={order_orderer?.order_orderer_address_detail ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_address_detail',e.target.value)} placeholder='나머지 주소'></input>
                        </InputItem>
                    </ItemInnerWrap>
                </ItemWrap>
            </ContentWrap>
            <OpenPlacePart open={placeOpen} setOpen={setPlaceOpen} setPlace={setPlace} />
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;
const ContentWrap = styled.div`
    margin-top: 15px;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    align-items: start;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;
const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const ItemInnerWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
`;

const InputItem = styled.div<{w?:string}>`
    border: 1px solid #e9e9e9;
    height: 42px;
    width: ${({w}) => w ?? '100%'};
    border-radius: 4px;
    &>input{
        height: 100%;
        width: 100%;
        padding: 10px 15px;
    }
`;

const BtnItem = styled(Center)`
    background-color: #909090;
    color: #fff;
    height: 42px;
    padding: 0 20px;
    flex-shrink: 0;
    font-size: 15px;
    border-radius: 4px;
    cursor: pointer;
`;
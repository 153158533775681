import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../../style/basic/commonStyle';
import { apiGetPhList } from '../../../../api/api_phone';
import PhoneListPartItem from '../item/PhoneListPartItem';

export default function PhoneListPart() {
    const [listG,setListG] = useState([]);
    const [listI,setListI] = useState([]);

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        const res = await apiGetPhList(1,150);
        if(res.result){
            const g = res.data.filter((data:any)=>data.phone_type === '1');
            const i = res.data.filter((data:any)=>data.phone_type === '2');
            setListG(g);
            setListI(i);
        }
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <PhoneListPartItem title={'굿폰몰 BEST 10'} list={listG} count={4} mt='80px'/>
                <PhoneListPartItem title={'갤럭시 최대 할인'} list={listG} count={4} mt='100px'/>
                <PhoneListPartItem title={'아이폰 최대할인'} list={listI} count={4}/>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import BasicText from '../../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { IoClose } from "react-icons/io5";
import { numberWithComma } from '../../../../utils/format/number';

const TITLE_5G:any = {
    "skt": {title: '5GX 플랜', content: '데이터 콘텐츠도 자유롭게! 초시대의 요금플랜'},
    "kt": {title: '5G 슈퍼플랜', content: '5G 데이터가 완전무제한에 해외에서도 끊김없는 데이터!'},
    "lg": {title: '5G 요금제', content: 'U+ 5G 서비스에 다양한 콘텐츠까지!'},
}

const ListItem = ({data,itemClick}:any) =>{
    return(
        <ItemWrap onClick={itemClick}>
            <ItemInnerItem>{data.plan_name}</ItemInnerItem>
            <ItemInnerItem>월 <span className='list-fw'>{numberWithComma(data.plan_price)}</span>원</ItemInnerItem>
            <ItemInnerItem>{data.plan_desc_1}</ItemInnerItem>
            <ItemInnerItem>{data.plan_desc_2}</ItemInnerItem>
            <ItemInnerItem>{data.plan_desc_3}</ItemInnerItem>
        </ItemWrap>
    )
}

export default function SelectPaymentOpen({open,setOpen,list,itemClick,network,nowUse}:any) {
        
    const close = () =>{
        setOpen(false);
    }

    const itemClickAction = (data:any) =>{
        close();
        if(itemClick){
            itemClick(data);
        }
    }
    
    return (
        <Dialog
            open={open}
            onClose={close}
            scroll={'paper'}
            sx={{
                '.MuiPaper-root':{maxWidth:'740px',width:'100%',margin:'20px'}
            }}
        >   
            <DialogTitle sx={{padding:2}}>
                <TitleWrap>
                    <img alt='logo' src={nowUse?.img} />
                    <BasicText color='#333' align='center' fontSize={28} mFontSize={24} fontWeight={700}>{nowUse?.name} 요금표</BasicText>
                    <TitleCloseItem><IoClose size={25} onClick={close} /></TitleCloseItem>
                </TitleWrap>
            </DialogTitle>
            <DialogContent sx={{padding:'15px'}}>
                <ContentTitleWrap>
                    <ContentTitleLeftWrap>
                        {network === '5G' ? 
                            <>
                                <BasicText fontWeight={700} fontSize={25} mFontSize={20}>{TITLE_5G[nowUse?.value].title}</BasicText>
                                <span>{network}</span>
                            </> :
                            <></>
                        }
                    </ContentTitleLeftWrap>
                    <ContentTitleRightWrap>
                        <BasicText>{network === '5G' ? TITLE_5G[nowUse?.value].content : ''}</BasicText>
                    </ContentTitleRightWrap>
                </ContentTitleWrap>
                <ContentListWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data:any,index:any) =>{
                            return(
                                <ListItem key={`payment-item${index}`} data={data} itemClick={()=>itemClickAction(data)}/>
                            )
                        })
                    }
                </ContentListWrap>
            </DialogContent>
            <DialogActions sx={{display:'block',padding:'5px 15px'}}>
                <div>
                    <BasicText fontSize={15} >※ 공시지원금(휴대폰 가격 할인) 선택시 186일 후 요금제 하향 가능</BasicText>
                    <BasicText fontSize={15} marginTop={1}>※ 선택약정할인(통신 요금 25% 할인) 선택시 125일 후 요금제 하향 가능</BasicText>
                </div>
            </DialogActions>
        </Dialog>
    )
}

const TitleWrap = styled(Center)`
    gap: 15px;
`;

const ContentTitleWrap = styled(RowBetween)`
    background-color: #333;
    color: #fff;
    padding: 18px 15px;
    flex-wrap: wrap;
    position: relative;
`;

const TitleCloseItem = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    & svg{
        cursor: pointer;
    }
`;

const ContentTitleLeftWrap = styled(Row)`
    gap: 6px;
    & span{
        border: 1px solid #fff;
        padding: 2px 4px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 1.1;
    }
`;
const ContentTitleRightWrap = styled(Row)`
    width: auto;
    flex-shrink: 0;
`;

const ContentListWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #e5e5e5;
    border-bottom: 2px solid #777;
`;

const ItemWrap = styled(Row)`
    border-bottom: 1px solid #e5e5e5;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover{
        background-color: #edeefd;
    }
`;
const ItemInnerItem = styled.div`
    width: 20%;
    text-align: center;
    padding: 10px;
    position: relative;
    font-size: 18px;
    &:nth-of-type(1){
        font-size: 20px;
        text-align: left;
        ::after{
            content: none;
        }
        @media ${addTheme.media.s}{
            width: 100%;
        }
    }
    &::after{
        content: '';
        position: absolute;
        background-color: #e5e5e5;
        width: 1px;
        height: 50px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    & .list-fw{
        font-weight: 700;
    }
    & .list-fz{
        font-size: 16px;
    }

    @media ${addTheme.media.s}{
        width: 50%;
        ::after{
            content: none;
        }
    }
`;
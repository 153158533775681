import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useNavigate, useParams } from 'react-router';
import ReviewItem from '../item/ReviewItem';
import ReviewDetailPart from '../part/ReviewDetailPart';
import { apiGetReviewList } from '../../../api/api_review';
import ContentPageItem from '../../common/item/ContentPageItem';

export default function ReviewPage() {
    const navigate = useNavigate();
    const {page} = useParams();
    const [total,setTotal] = useState(0);
    const [list,setList] = useState([]);
    const [open,setOpen] = useState(false);
    const [item,setItem] = useState<any>();

    useEffect(()=>{
        if(open){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[open])

    useEffect(()=>{
        getList();
    },[page])

    const getList = async() =>{
        const res = await apiGetReviewList(page ?? 1,9);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const itemClick = (data:any) =>{
        setOpen(true);
        setItem(data);
    }

    const setPage = (p:any) =>{
        navigate(`/review/${p}`);
    }

    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center' spColor={addTheme.color}><span>굿폰몰</span> BEST 구매후기</BasicText>
                    <BasicText fontSize={20} mFontSize={15}  align='center' marginTop={1}>고객님들께서 남겨주신 생생 리얼 후기</BasicText>
                </TitleWrap>
                <ContentWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data:any,index:any)=>{
                            return(
                                <ItemWrap key={`review-${index}`}>
                                    <ReviewItem data={data} itemClick={()=>itemClick(data)}/>
                                </ItemWrap>
                            )
                        })
                    }
                </ContentWrap>
                <PageWrap>
                    <Btn></Btn>
                    <ContentPageItem page={page ?? 1} setPage={setPage} total={total} btnState='false' />
                    <BtnItem onClick={()=>navigate('/reviewCreate')}>후기작성</BtnItem>
                </PageWrap>
            </Wrap>
            <ReviewDetailPart open={open} setOpen={setOpen} item={item} setItem={setItem}/>
            {/* <QnaContactItem /> */}
        </MaxWidthWrap>
    )
}
const Wrap = styled.div``;
const TitleWrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 65px;
    gap: 25px 15px;
    flex-wrap: wrap;
`;

const ItemWrap = styled.div`
    width: calc(33.33% - 10px);
    @media ${addTheme.media.m}{
        width: calc(50% - 10px);
    }
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const PageWrap = styled(RowBetween)`
    flex-wrap: wrap;
    margin-top: 25px;
    width: 100%;
    @media ${addTheme.media.s}{
        flex-direction: column;
        row-gap: 15px;
    }
`;

const Btn = styled(Center)`
    width: 145px;
    height: 44px;
    @media ${addTheme.media.s}{
        display: none;
    }
`;

const BtnItem = styled(Btn)`
    background-color: ${addTheme.color};
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    @media ${addTheme.media.s}{
        display: flex;
        order: -1;
        align-self: end;
    }
`;
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { USE_CARRIER } from '../../../../data/data';
import SelectSizeItem from '../item/SelectSizeItem';
import SelectColorItem from '../item/SelectColorItem';
import SelectUseItem from '../item/SelectUseItem';
import SelectNowUseItem from '../item/SelectNowUseItem';
import SelectPaymentItem from '../item/SelectPaymentItem';
import SelectDiscountItem from '../item/SelectDiscountItem';
import SelectBuyTypeItem from '../item/SelectBuyTypeItem';
import SelectCardItem from '../item/SelectCardItem';
import { TEL_LIST_CATE } from '../../../../data/payment';
import { ADMIN_IMG_URL } from '../../../../api/config';

export default function SelectPart({info,size,color,use,nowUse,payment,discountType,buyType,card,sellItem}:any) {
    const [img,setImg] = useState('');
    useEffect(()=>{
        if(info?.phone_id === undefined) return;
        if(color?.color.color_id !== undefined){
            setImg(`${ADMIN_IMG_URL}product_image/product_phone_${info?.phone_id}_${color?.color?.color_id}.jpg`);
        }
    },[color?.color?.color_id,info?.phone_id]);

    const handleImageError = (event:any) => {
        // event.target.onerror = null; 
        // event.target.src = info?.phone_thumbnail;
        event.target.style.display = 'none';
    };

    return (
        <Wrap>
            <ImgWrap>
                <img src={info?.phone_thumbnail} alt='phone-img'></img>
                <img style={{position:'absolute',top:0,left:0,right:0,margin:'0 auto',zIndex:2}} src={img} alt='phone-img' onError={handleImageError}></img>
            </ImgWrap>
            <SelectWrap>
                <SelectSizeItem list={info?.phone_volume} size={size} />
                <SelectColorItem list={info?.phone_colors} color={color}/>
                <SelectUseItem list={USE_CARRIER} action={use} />
                <SelectNowUseItem list={TEL_LIST_CATE} action={nowUse} use={use.use}/>
                <SelectPaymentItem list={payment.list} action={payment} network={info?.phone_network} nowUse={nowUse.get} />
                <SelectDiscountItem discountItem={payment?.get} action={discountType} />
                <SelectBuyTypeItem action={buyType} />
                <SelectCardItem list={card.list} action={card} nowUse={nowUse.get} />
                {/* <SelectUsePhoneItem action={sellItem} /> */}
            </SelectWrap>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    border-top: 1px solid #000;
    padding-top: 10px;
    flex-wrap: wrap;
    align-items: start;
`;

const ImgWrap = styled(Center)`
    width: 45%;
    margin: 25px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    & img{
        width: 100%;
        max-width: 300px;
    }
    @media ${addTheme.media.l}{
        width: 100%;
        text-align: center;
        margin: 15px 0;
    }
`;
const SelectWrap = styled(Row)`
    width: 53%;
    flex-direction: column;
    align-items: stretch;
    gap: 18px;
    @media ${addTheme.media.l}{
        width: 100%;
    }
`;
import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷', channel:0, M100:{pay0:22000,pay1:'-',gift:10},M200:{pay0:24200,pay1:'-',gift:12}, M500:{pay0:33000,pay1:'-',gift:14}, G1:{pay0:38500,pay1:'-',gift:15}},
    {name: '인터넷 + 올', channel:239, M100:{pay0:29700,pay1:20900,gift:35},M200:{pay0:31900,pay1:23100,gift:36}, M500:{pay0:37400,pay1:'-',gift:42}, G1:{pay0:42900,pay1:'-',gift:45}},
    {name: '인터넷 + 포인트', channel:239, M100:{pay0:31900,pay1:23100,gift:35},M200:{pay0:34100,pay1:25300,gift:36}, M500:{pay0:39600,pay1:'-',gift:42}, G1:{pay0:45100,pay1:'-',gift:45}},
    {name: '인터넷 + 초이스', channel:239, M100:{pay0:37400,pay1:28600,gift:35},M200:{pay0:39600,pay1:30800,gift:36}, M500:{pay0:45100,pay1:'-',gift:42}, G1:{pay0:50600,pay1:'-',gift:45}},
    
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [
        {product: '신혼플러스', terms: '예비 부부, 신혼 부부 5g: 85요금제 LTE 78 요금제 이상', benefit: '모바일 요금 6개월 무료 + 월 최대 22,850원 할인'},
        {product: '펫플러스', terms: '스마트홈', benefit: '공기청정기 렌탈료 최대 21,000원 할인 구매 시 약 23만원 할인'},
        {product: '시니어 플러스', terms: '약 65세 이상 사용자', benefit: '인터넷 3,100원 추가 할인'},
    ] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '투게더 결합', terms: '모바일 5회선, 인터넷 5회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 11,000원/ 휴대폰 최대 20,000원 할인'},
        {product: '참 쉬운 가족결합', terms: '모바일 10회선,인터넷 3회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 13,200원/ 휴대폰 최대 5,500원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: 'BC카드', list: [
        {name: 'KT 마이알뜰폰 BC바로카드', benefit: '알뜰폰요금 자동이체시 30만원 이상 실적 19,000원 할인'},
    ]},
    {name: 'IBK기업은행	', list: [
        {name: 'SKYLIFE Ultra IBK카드', benefit: '30만원 이상 실적 4,000원 할인'},
    ]},
    {name: '롯데카드', list: [
        {name: 'LOCA X 구독 카드', benefit: '30만원 이상 실적 18,000원 할인'},
    ]},
    
]

export default function SkyLifeStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`스카이라이프 요금표 한눈에 보기`} />
            <TableWrap>
                <TableWrapItem margin='40px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2} colSpan={2}>인터넷 상품</th>
                            <th className='title-bg' rowSpan={2}>모바일<br/>결합여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>200M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LIST.map((data:any,index:any)=>{
                            const evenState = (index+1) % 2 === 0;
                            return(
                                <React.Fragment key={`kt-pay-${index}`}>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <th className='content-title' rowSpan={2} colSpan={2}>
                                            {data.name}
                                            {data.channel !== 0 &&
                                                <>
                                                    <br/><span>{data.channel}채널</span>
                                                </>
                                            }
                                        </th>
                                        <td className='first-text'>미결합</td>
                                        <td className='first-text'>{numberWithComma(data['M100']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M100']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M200']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M200']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M500']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M500']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['G1']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['G1']['gift'])}만원</td>
                                    </tr>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <td>홈결합<p style={{fontSize:'13px',color:'#8b939b',fontWeight:400}}>(사은품x)</p></td>
                                        <td>{data['M100']['pay1'] === '-' ? data['M100']['pay1'] : `${numberWithComma(data['M100']['pay1'])}원`}</td>
                                        <td>{data['M200']['pay1'] === '-' ? data['M200']['pay1'] : `${numberWithComma(data['M200']['pay1'])}원`}</td>
                                        <td>{data['M500']['pay1'] === '-' ? data['M500']['pay1'] : `${numberWithComma(data['M500']['pay1'])}원`}</td>
                                        <td>{data['G1']['pay1'] === '-' ? data['G1']['pay1'] : `${numberWithComma(data['G1']['pay1'])}원`}</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'할인혜택 모르면 나만 손해'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='결합할인은 필수' />
                    <BasicText>인터넷 가입 시 결합할인을 생각하지 않는 건,<br/>돈을 버린다는 의미와 같습니다.</BasicText>
                    <BasicText marginTop={2}>반드시 확인하여 최저요금에 도전해봅시다!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="23%" />
                                <col width="24%" />
                                <col width="53%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>결합 상품</th>
                                    <th>결합 조건</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}>TV + 인터넷</th>
                                    <td>홈 결합</td>
                                    <td>인터넷과 TV 각각 1회선 이상</td>
                                </tr>
                                <tr>
                                    <td>기가 안심 홈 결합</td>
                                    <td>500M 이상 인터넷+ TV 각각 1회선 이상</td>
                                </tr>
                                <tr>
                                    <th>TV + 인터넷 + 모바일</th>
                                    <td>모바일 알뜰 결합</td>
                                    <td> KT 스카이라이프 알뜰폰 후불요금제<br/> 1~2회선 + 인터넷TV 결합 </td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>혹시 어려우시다면 언제든 문의 주세요.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금 자동이체 시 할인혜택이 적용됩니다.<br/>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_CARD.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-card-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-card-item-${index}-${idx}`}>
                                                            <td>{item['name']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-card-item-${index}-${idx}`}>
                                                        <td>{item['name']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전 대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>저희 <span>굿폰몰</span>로 문의주시면<br/>KT, LG, SK 모두 확인해서 알려드리겠습니다^^</BasicText>
                    <BasicText marginTop={2}>(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2} >전화가 부담스러우신 분은<br/>아래링크에 주소를 입력해서 확인해주세요!</BasicText>
                    <BasicText marginTop={2} fontWeight={700} deco='underline'><a href="https://help.kt.com/serviceinfo/AddrSearchList.do" target="_blank" rel="noopener noreferrer">※ 스카이라이프 대칭형 인터넷 설치지역 조회 링크</a></BasicText>
                    <BasicText marginTop={2} >거주지 조회 후 1기가 인터넷 설치가 가능하다면 대칭형입니다.</BasicText>
                    <BasicText marginTop={2} >참고로 스카이라이프는 KT망을 임대하여 사용하는데요.<br/>KT는 99.9% 대칭형이기 때문에 안심하셔도 좋습니다!</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5}>굿폰몰에 명시된 요금은 <strong>셋톱박스, 모뎀 임대료 및 부가가치세가</strong><br/>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며 약정 후 꼭 반납을 해야합니다.<br/>혹시나 잃어버리지 않게 주의하세요!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="35%" />
                                <col width="28%" />
                                <col width="35%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                    <th>특징</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>안드로이드 4</td>
                                    <td>1,100원</td>
                                    <td>구글 캐스트, 미러링</td>
                                </tr>
                                <tr>
                                    <td>안드로이드 5</td>
                                    <td>1,100원</td>
                                    <td>구글 캐스트, 미러링</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>TV 채널 편성이 궁금하다면 <strong><a style={{display:'inline-block'}} href="https://www.skylife.co.kr/product/tv/channelNo/category" target="_blank" rel="noopener noreferrer">여기</a></strong>를 클릭해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>유튜브</th>
                                    <th>넷플릭스</th>
                                    <th>디즈니+</th>
                                    <th>웨이브</th>
                                    <th>왓챠</th>
                                    <th>티빙</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>안드로이드4</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>△</td>
                                    <td>△</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>안드로이드5</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>△</td>
                                    <td>△</td>
                                    <td>O</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='05' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} spColor={addTheme.color}>*<span>굿폰몰</span>는 설치 후 9개월 사용으로 사은품 반환의무가 없어진답니다.<br/>(타사는 대부분 12개월)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>인터넷 단독</th>
                                    <th>TV 단독</th>
                                    <th>인터넷<br/>+ TV</th>
                                    <th>TV추가<br/>(1대 당)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>평일</th>
                                    <td>36,000원</td>
                                    <td>27,500원</td>
                                    <td>48,500원</td>
                                    <td>+11,000원</td>
                                </tr>
                                <tr>
                                    <th>주말</th>
                                    <td>45,000원</td>
                                    <td>27,500원</td>
                                    <td>57,500원</td>
                                    <td>+11,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생하며, 이사를 한다면 이전설치비가 청구됩니다(비용동일)</BasicText>
                    </TextRowWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>스카이라이프의 경우 일요일, 공휴일은 설치가 불가능한 경우가 많습니다.</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// type01
// import HeaderPage from '../../type01/header/page/HeaderPage';

// type02
import HeaderPage from '../../type02/header/page/HeaderPage';

import { Outlet, useLocation } from 'react-router';
import FooterPart from '../part/FooterPart';
import RightBtnPart from '../../common/part/RightBtnPart';
import PopupPart from '../../common/part/PopupPart';

export default function LayoutPage() {
    const location = useLocation();
    const headerRef = useRef<any>(null);
    const footerRef = useRef<any>(null);
    const [height, setHeight] = useState(0);
    const [fHeight,setFHeight] = useState(0);
    const [mt,setMt] = useState(0);

    useEffect(()=>{
        if(location.pathname === '' || location.pathname === '/' || location.pathname === '/partner'){
            setMt(0);
        }else{
            setMt(40);
        }
    },[location.pathname])

    const getHeight = () =>{
        if (headerRef.current){
            setHeight(headerRef.current.clientHeight); 
        }
        if(footerRef.current){
            setFHeight(footerRef.current.clientHeight); 
        }
    }

    useEffect(()=>{
        getHeight();
        window.addEventListener('resize', getHeight); 

        return () => {
            window.removeEventListener('resize', getHeight);
        };
    },[]);
    
    return (
        <Wrap>
            <HeaderWrap ref={headerRef}>
                <HeaderPage />
            </HeaderWrap>
            <HeaderSpaceWrap h={height}></HeaderSpaceWrap>
            <ContentWrap h={height} fh={fHeight} mt={mt}>
                <RightBtnPart />
                <Outlet />
                <PopupPart />
            </ContentWrap>
            <FooterWrap ref={footerRef}>
                <FooterPart />
            </FooterWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const HeaderWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
`;
const HeaderSpaceWrap = styled.div<{h:any}>`
    height: ${({h}) => h ?? 0}px;
    opacity: 0;
`;
const ContentWrap = styled.div<{h:any,fh:any,mt:number}>`
    min-height: ${({h,fh}) => `calc(100vh - ${h ?? 0}px - ${fh ?? 0}px)`};
    margin-top: ${({mt}) => mt ?? 0}px;
`;

const FooterWrap = styled.div`
    margin-top: 60px;
`;
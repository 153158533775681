import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Center, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { useNavigate, useParams } from 'react-router';
import { addTheme } from '../../../../style/addTheme';
import { ADMIN_PRODUCT_LIST } from '../../../admin/data/table'
import { TableCell, TableRow } from '@mui/material';
import { numberWithComma } from '../../../../utils/format/number';
import { apiAdmGetPhProduct } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { PRODUCT_STATE } from '../../../../data/state';

const PHONE_TYPE = ['갤럭시','아이폰'];

const ListItem = ({data,goDetail}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[0].align}>{data.phone_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[1].align}>{PHONE_TYPE[Number(data.phone_type)-1] }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[2].align}>{data.phone_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[3].align}>{data.phone_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[4].align}>{numberWithComma(data.phone_upgrade_price_1)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[5].align}>{PRODUCT_STATE[Number(data.phone_visible)]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_PRODUCT_LIST[6].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={goDetail}>상세</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminProductPage() {
    const navigate = useNavigate();
    const {page} = useParams();
    const [total,setTotal] = useState(0);
    const [list,setList] = useState([]);
    const adminUser= useAdminUser();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[page,adminUser.admin_id])

    const setPage = (p:any) =>{
        navigate(`/adm/ph/product/${p}`);
    }

    const goDetail = (id:any) =>{
        navigate(`/adm/ph/productDetail/${id}`);
    }

    const getList = async() =>{
        const res = await apiAdmGetPhProduct(adminUser.admin_token,adminUser.admin_id,page,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total)
        }else{
            alert(res.error);
        }
    }

    const goUpdate = () =>{
        navigate('/adm/ph/productAdd');
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 상품' part='product'>
                <BtnItem bg={addTheme.color} onClick={()=>goUpdate()}>상품 등록</BtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>상품 관리</BasicText>
                </TitleWrap>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADMIN_PRODUCT_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`product-${index}`} data={data} goDetail={()=>goDetail(data.phone_id)} />
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;

import { AppBar, Box, Toolbar, useMediaQuery, } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme, } from '@mui/material/styles';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router';
import MenuPart from '../part/MenuPart';
import { addTheme } from '../../../../style/addTheme';
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { IoMdLogOut } from "react-icons/io";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { setAdminUser } from '../../../../store/common/adminUser';

const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  background: addTheme.adminColor,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  top:'auto',
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: addTheme.adminColor,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '57px',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: '57px',
    // width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface LeftNavBarProps {
  idKey?: any;
  children?: any;
  titleText?: string;
  routerHistory?: any;
}

export default function AdmLayoutPage({idKey, children, titleText, routerHistory, ...props}: LeftNavBarProps) {
    const barRef = React.useRef<any>(null);
    const [headerHeight, setHeaderHeight] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery(addTheme.media.sx);
    const dispatch = useDispatch();
    const adminUser = useAdminUser();
    
  React.useEffect(()=>{
    if(adminUser.admin_id === 0){
      navigate('/');
    }
  },[adminUser.admin_id])

    const logout = () =>{
      if(window.confirm('로그아웃 하시겠습니까?')){
        dispatch(setAdminUser({
          admin_id: 0,
          admin_token: '',
        }));
        alert('로그아웃 되었습니다.');
      }
    }

    const GoHistory = React.useCallback((router: string,type?:string) => {
        if(type==='out'){
            window.open(router);
            return;
        }
        navigate(router);
    },[]);

    React.useEffect(()=>{
        const handleResize = () => {
            if (barRef.current) {
                const height = barRef.current.offsetHeight;
                setHeaderHeight(height);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    },[])

  return(
    <>
        {/* <HeaderPart></HeaderPart> */}
        <Box sx={{
            display: 'flex',
            background:'#f1f5f9',
            minHeight: mediaQuery ? '100vh':'100vh'
        }}>
            <Drawer variant="permanent" open={open} sx={{
                zIndex:1500,
                flexShrink: 0,
                borderRadius:'4px',
                '.MuiPaper-elevation':{borderRight:'none !important',},
            }}>
            <RowBetween style={{flexDirection:'column',alignItems:'stretch',height:'100%'}}>
                <div>
                    <LogoWrap>
                        {open === true &&
                            <>
                            <AdminIconItem>ADMIN</AdminIconItem>
                            <BasicText color='#fff' fontSize={22} fontWeight={700} marginTop={1} onClick={()=>GoHistory('/adm')}>굿폰몰</BasicText>
                            </>
                        }
                        <OpenIconWrap>
                            {open === true ?
                            <ChevronLeftIcon onClick={()=>setOpen(false)} sx={{color:'#fff'}}/> :
                            <ChevronRightIcon onClick={()=>setOpen(true)} sx={{color:'#fff'}}/>
                            }
                        </OpenIconWrap>
                    </LogoWrap>
                    <div style={{padding:'10px 0',marginTop:'10px',flexDirection:'column',alignItems:'stretch', display: open? 'flex' : 'none'}}>
                        <div>
                            <MenuPart></MenuPart>
                        </div>
                    </div>
                </div>
                <Row style={{padding:'0 0 50px 20px', gap:'10px', cursor:'pointer'}}>
                    <IoMdLogOut color='#fff' size={20}/>
                    {open === true &&
                        <BasicText color='#fff' fontSize={18} onClick={logout}>로그아웃</BasicText>
                    }
                </Row>
            </RowBetween>
            </Drawer>
            <Box component="main" sx={{ width:open === true ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 57px)`, overflowX: mediaQuery ? 'hidden' : 'visible' }}>
                <Box sx={{p: 2, backgroundColor:'#f1f5f9'}}>
                    {/* {children} */}
                    <Outlet />
                </Box>
            </Box>
        </Box>
    </>
    )
}

const LogoWrap = styled(Center)`
  margin-top: 15px;
  flex-direction: column;
  min-height: 67px;
  position: relative;
  & p{
    cursor: pointer;
  }
`;

const OpenIconWrap = styled(Center)`
    position: absolute;
    top: 0px;
    right: 15px;
    cursor: pointer;
`;
const AdminIconItem = styled(Center)`
    background-color: #fff;
    color: ${addTheme.adminColor};
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 30px;
`;
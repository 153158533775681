import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import KtStepItem from '../item/KtStepItem';
import BasicText from '../../basic/BasicText';
import { RiKakaoTalkFill } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme';
import LgStepItem from '../item/LgStepItem';
import SkStepItem from '../item/SkStepItem';
import SkyLifeStepItem from '../item/SkyLifeStepItem';
import LgHelloStepItem from '../item/LgHelloStepItem';
import SKBStepItem from '../item/SKBStepItem';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function StepPart({selectData}:any) {
    const companyInfo = useGlobalCompanyInfo();
    const phoneClick = () =>{
        if(companyInfo['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + companyInfo['config_company_call_number'];
    }
    const kakaoClick = () =>{
        if(companyInfo['config_social_kakao_link'] === undefined) return;
        window.open(companyInfo['config_social_kakao_link']);
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <ContentWrap>
                        {selectData?.tel?.company_id === 1 &&
                            <KtStepItem selectData={selectData} />
                        }
                        {selectData?.tel?.company_id === 2 && 
                            <LgStepItem selectData={selectData} />
                        }
                        {selectData?.tel?.company_id === 3 &&
                            <SkStepItem selectData={selectData} />
                        }
                        {selectData?.tel?.company_id === 4 &&
                            <SkyLifeStepItem selectData={selectData}  />
                        }
                        {selectData?.tel?.company_id === 5 &&
                            <LgHelloStepItem selectData={selectData} />
                        }
                        {selectData?.tel?.company_id === 6 &&
                            <SKBStepItem selectData={selectData} />
                        }
                    </ContentWrap>
                    <ContactWrap>
                        <BasicText align='center' fontWeight={700} fontSize={24} mFontSize={18}>더 궁금하신 사항이 있다면 부담없이 굿폰몰로 문의주세요!</BasicText>
                        <BasicText align='center' fontWeight={700} fontSize={24} mFontSize={18}>365일 아침 8시부터 저녁 10시까지 열려있습니다.</BasicText>
                        <BtnWrap>
                            <BtnItem onClick={()=>phoneClick()} borderColor='#ff7500' bg='#fff' color='#ff7500'>
                                <FaPhoneAlt size={20}/>
                                전화상담 1533-9360
                            </BtnItem>
                            <BtnItem onClick={()=>kakaoClick()} bg='#fee500'>
                                <RiKakaoTalkFill size={28}/>
                                카카오톡 상담 바로가기
                            </BtnItem>
                        </BtnWrap>
                    </ContactWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 50px;
`;

const ContentWrap = styled.div`
    & table{
        width: 100%;
    }
    & th{
        padding: 6px 4px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #e7e7eb;
        letter-spacing: -.2px;
        line-height: 1.5;
        font-weight: 700;
    }
    & th p{
        font-size: 13px;
        color: #8b939b;
    }
    & td{
        border: 1px solid #e7e7eb;
        padding: 6px 4px;
        text-align: center;
        vertical-align: middle;
        letter-spacing: -.2px;
        line-height: 1.5;
        font-weight: 700;
    }
    & table .title-bg{
        background-color: #fff2ec;
    }
    & table .content-bg{
        /* background-color: #f9f9fb; */
    }
    /* & table tbody tr th:nth-of-type(1){
        background-color: #f9f9fb;
    } */
    & table .content-title{
        font-weight: 700;
    }
    & table span{
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        background-color: #f00000;
        border-radius: 6px;
    }
    & table .gift{
        color: #f25a59;
        font-weight: 700;
    }

    & table .first-text{
        color: #747f8a;
    }
    .table-map tr span{
        color: ${addTheme.color};
        background-color: transparent;
        padding: 0;
        font-size: 16px;
    }
    & table .green{
        background-color: #3cc263;
        color: #fff;
        padding: 4px 8px;
        font-size: 12px;
        margin-left: 5px;
    }
    & table .text-green{
        color: #3cc263;
        background-color: transparent;
        padding: 0;
        font-size: 16px;
    }
    & table{
        max-width: 100%;
        img{
            width: 100%;
        }
    }
`;

const ContactWrap = styled(Center)`
    border-top: 1px solid #c4c4c4;
    flex-direction: column;
    padding: 50px 0;
    margin-top: 50px;
`;

const BtnWrap = styled(Center)`
    margin-top: 20px;
    gap: 20px;
    width: 100%;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;

const BtnItem = styled(Center)<{bg?:string,color?:string,borderColor?:string}>`
    background-color: ${({bg}) => bg ?? '#fff'};
    color: ${({color}) => color ?? '#000'};
    padding: 8px 15px;
    gap: 8px;
    max-width: 240px;
    width: 100%;
    height: 46px;
    border-radius: 30px;
    border: 1px solid ${({borderColor,bg}) => borderColor === undefined ? (bg ?? '#fff') : borderColor};
    cursor: pointer;
`;
import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

const LIST = [
    {title:'회원가입으로<br/>수익 창출 준비', desc: '굿폰몰 파트너스 회원으로<br/>수익 창출 자격 획득', img: '/img/asset/icon11.png'},
    {title:'단 하나의 코드로<br/>모든 구매 내역 추적', desc: '복잡하고 어려운건 NO!<br/>간단하게 내 코드 발급!', img: '/img/asset/icon12.png'},
    {title:'굿폰몰 상품<br/>홍보 진행', desc: '나만의 홍보 방식으로<br/>다양하고 자유롭게 진행!', img: '/img/asset/icon13.png'},
    {title:'구매 전환으로<br/>수익 창출', desc: '매월 수익금을 정산하여<br/>회원님의 통장으로 쏙쏙!', img: '/img/asset/icon14.png'},
]

export default function PartnerActivePart() {
    return (
        <Wrap>
            <BasicText align='center' fontWeight={700} fontSize={38} mFontSize={26}>당신의 활동이 곧 수익!</BasicText>
            <BasicText align='center' marginTop={0.8} fontSize={19} mFontSize={17}>SNS, Blog 등으로 굿폰몰를 홍보해 주세요.</BasicText>
            <BasicText align='center' fontSize={19}>구매 전환 시, 비교 불가한 국내 최대 수익금으로 보다 안정적인 수익 창출을 약속드립니다.</BasicText>
            <ContentWrap>
                {LIST.map((data:any,index:any)=>{
                    return(
                        <ContentItemWrap key={`active-${index}`}>
                            <NumberWrap>0{index+1}</NumberWrap>
                            <ImgWrap>
                                <img alt='active-icon' src={data.img}></img>
                            </ImgWrap>
                            <TitleItem dangerouslySetInnerHTML={{__html:data.title}} />
                            <DescItem  dangerouslySetInnerHTML={{__html:data.desc}}/>
                        </ContentItemWrap>
                    )
                })}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;

const ContentWrap = styled(RowBetween)`
    gap: 20px;
    align-items: start;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        &>div{
            width: calc(50% - 10px);
        }
        gap: 10px;
    }
`;

const ContentItemWrap = styled.div`
    margin-top: 80px;
`;
const NumberWrap = styled(Center)`
    background-color: #f8f8f8;
    width: 46px;
    height: 46px;
    border-radius: 42px;
    font-size: 18px;
    margin: 0 auto;
`;

const ImgWrap = styled(Center)`
    margin-top: 20px;
    & img{
        width: 100%;
        max-width: 85px;
    }
`;

const TitleItem = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 16px;
    @media ${addTheme.media.s}{
        font-size: 18px;
    }
`;
const DescItem = styled.div`
    text-align: center;
    font-size: 17px;
    margin-top: 10px;
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
`;
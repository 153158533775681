import React from 'react'
import styled from 'styled-components'
import { Center, ContentWrap, MaxWidthWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useNavigate } from 'react-router';

const STEP = [
    {id:1, title:'신청 접수 완료', content:`굿폰몰을 통해 가격 및 판매 조건을 확인하고\n신청접수를 진행해주세요.`},
    {id:2, title:'공식신청서 작성', content:`고객님 문자 또는 이메일로 전송된 통신사 공식\n신청서 작성 후 담당자께 연락부탁드리겠습니다.`},
    {id:3, title:'해피콜 진행', content:`담당자들 통하여 최종 해피콜을 진행합니다.\n추가 문의는 해피콜 시 진행해주세요.`},
    {id:4, title:'휴대폰 배송 및 개통', content:`휴대폰을 배송 및 개통을 진행합니다.\n5시 이전 신청서 작성시 당일 배송을 진행합니다.`},
]

const StepItem = ({data,stateIdx}:any) =>{
    return(
        <ItemWrap>
            <StepItemWrap state={data.id === stateIdx}>
                <BasicText color='#fff' fontSize={13}>STEP</BasicText>
                <BasicText color='#fff' fontSize={18}>0{data.id}</BasicText>
            </StepItemWrap>
            <StepTextWrap>
                <BasicText fontSize={17} fontWeight={700} mFontSize={16}>{data.title}</BasicText>
                <BasicText wh='pre-wrap' marginTop={0.5} mFontSize={15} lh={1.2}>{data.content}</BasicText>
            </StepTextWrap>
        </ItemWrap>
    )
}

export default function RegisterDonePage() {
    const navigate = useNavigate();
    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24}  fontWeight={700} >신청접수완료</BasicText>
                </TitleWrap>
                <DescWrap>
                    <BasicText fontSize={17} mFontSize={15} align='center'>고객님의 신청접수가 완료되었습니다.</BasicText>
                    <BasicText fontSize={17} mFontSize={15} align='center'>이후 진행 절차에 대하여 간단히 설명드리겠습니다.</BasicText>
                </DescWrap>
                <BasicText fontSize={28} mFontSize={20} align='center' marginTop={8} fontWeight={700}>진행 절차</BasicText>
                <StepWrap mt={50} gap={35}>
                    {STEP.map((data,index)=>{
                        return(
                            <StepItem key={`step-${index}`} data={data} stateIdx={2}/> 
                        )
                    })}
                </StepWrap>
                <BtnWrap onClick={()=>navigate('/myPagePh')}>마이페이지로 이동</BtnWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
`;

const TitleWrap = styled(Center)`
`;

const DescWrap = styled.div`
    margin-top: 50px;
    background-color: #fafafa;
    border-radius: 15px;
    padding: 30px 20px;
`;

const StepWrap = styled(ContentWrap)`
    background-color: #fafafa;
    border-radius: 15px;
    padding: 30px 20px;
`;

const BtnWrap = styled.button`
    width: calc(100% - 20px);
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin: 40px auto 0;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const ItemWrap = styled(Row)`
    gap: 20px;
`;

const StepItemWrap = styled(Center)<{state:boolean}>`
    flex-direction: column;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    background-color: ${({state}) => state ? addTheme.main : '#929292'};
    flex-shrink: 0;
    @media ${addTheme.media.sx}{
        width: 70px;
        height: 70px;
    }
`;

const StepTextWrap = styled.div``;
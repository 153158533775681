import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtn, AdmBtnItem, AdminWrap, Center, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import AdminTablePart from '../../common/part/AdminTablePart';
import BasicText from '../../../basic/BasicText';
import { TableCell, TableRow } from '@mui/material';
import { ADMIN_ORDER_LIST } from '../../data/table';
import { ORDER_STATUS, ORDER_TYPE, TEL_LIST } from '../../../../data/payment';
import { momentFormat, nowTime } from '../../../../utils/format/time';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetOrder, apiAdmSetOrderDel } from '../../../../api/api_adm_ph_order';
import * as XLSX from 'xlsx';

const Search = ({searchAction}:any) =>{
    const [sc,setSc] = useState('');
    const search = (e:any) =>{
        e.preventDefault();
        searchAction(sc);
    }
    return(
        <SearchWrap onSubmit={search}>
            <input value={sc} onChange={(e:any)=>setSc(e.target.value)} placeholder='고객명, 전화번호'></input>
            <SearchBtnItem type='submit'>검색</SearchBtnItem>
        </SearchWrap>
    )
}

const ListItem = ({data,goDetail,delClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[0].align}>{data.order_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[1].align}>{TEL_LIST[data.telco_id].name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[2].align}>{ORDER_TYPE[data.order_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[3].align}>{data.phone_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[4].align}>{data.order_user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[5].align}>{data.order_user_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[6].align}>{ORDER_STATUS[data.order_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[7].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[8].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_process_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_LIST[9].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={goDetail}>상세</AdmBtnItem>
                    <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={delClick}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminPhOrderPage() {
    const {page} = useParams();
    const adminUser = useAdminUser();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const navigate = useNavigate();
    const [search,setSearch] = useState('');

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[page,search,adminUser.admin_id])

    const setPage = (p:any) =>{
        navigate(`/adm/ph/order/${p}`);
    }

    const goDetail = (id:any) =>{
        navigate(`/adm/ph/orderDetail/${id}`);
    }

    const getList = async() =>{
        const res = await apiAdmGetOrder(adminUser.admin_token,adminUser.admin_id, page ?? 1, 15, search);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const searchAction = (sc:string) =>{
        setSearch(sc);
        setPage(1);
    }

    const delClick = async(id:any) =>{
        if(id === undefined) return;
        if(window.confirm('삭제 하시겠습니까?')){
            const res = await apiAdmSetOrderDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('삭제 했습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    const xlsxDown = async() =>{
        const res = await apiAdmGetOrder(adminUser.admin_token,adminUser.admin_id,1,999999999, '');
        if(res.result){
            if(res.data.length === 0) return;
            const newList = res.data.map((data:any)=>({
                '번호': data.order_id,
                '통신사': TEL_LIST[data.telco_id].name,
                '주문유형': ORDER_TYPE[data.order_type],
                '모델명': data.phone_model,
                '고객명': data.order_user_name,
                '연락처': data.order_user_phone_number,
                '처리상태': ORDER_STATUS[data.order_status],
                '주문시간': momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp),
                '처리시간':  momentFormat('YYYY-MM-DD hh:mm',data.order_process_timestamp),
            }))
            const worksheet = XLSX.utils.json_to_sheet(newList);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const time = nowTime();
            // 파일 다운로드
            XLSX.writeFile(workbook, `phone_order_list_${time}.xlsx`);
        }
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 주문' part='order' >
                <AdmBtn bg={'#000'} onClick={xlsxDown}>엑셀다운로드</AdmBtn>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>주문관리</BasicText>
                    <Search searchAction={searchAction}/>
                </TitleWrap>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADMIN_ORDER_LIST} minWidth={1250} page={page ?? 1} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`order-${index}`} data={data} goDetail={()=>goDetail(data.order_id)} delClick={()=>delClick(data.order_id)} />
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;
const SearchWrap = styled.form`
    width: 65%;
    justify-content: end;
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    & input{
        width: 100%;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        max-width: 280px;
    }
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;
const SearchBtnItem = styled.button`
    background-color: ${addTheme.adminColor ?? '#000'};
    color: #fff;
    height: 42px;
    width: 60px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;
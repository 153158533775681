import React from 'react'
import styled from 'styled-components'
import PartnerBannerPart from '../part/PartnerBannerPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import PartnerActivePart from '../part/PartnerActivePart';
import PartnerProcess from '../part/PartnerProcess';
import PartnerBtnPart from '../part/PartnerBtnPart';

export default function PartnerPage() {
    return (
        <Wrap>
            <PartnerBannerPart />
            <MaxWidthWrap>
                <PartnerActivePart />
                <PartnerProcess />
                <PartnerBtnPart />
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import MyPageStepItem from '../item/MyPageStepItem';
import { addTheme } from '../../../style/addTheme';
import RegisterPhoneInfoPart from '../../phone/register/part/RegisterPhoneInfoPart';
import RegisterPayPart from '../../phone/register/part/RegisterPayPart';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { apiGetPhOrder } from '../../../api/api_mypage';
import { useNavigate } from 'react-router';
import { apiGetDiscount } from '../../../api/api_phone';
import { discountPlanRes } from '../../../utils/format/number';


export default function MyPagePart({loginChk}:any) {
    const navigate = useNavigate();
    const [item,setItem] = useState<any>('');
    const [itemList,setItemList] = useState<any>([]);
    const [info,setInfo] = useState<any>();
    const [cardDiscount,setCardDiscount] = useState(0);
    const userInfo = useUserInfo();

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        if(loginChk){
            getList();
        }
    },[loginChk,userInfo.jwt_token]);

    useEffect(()=>{
        if(item === '') return;
        if(itemList.length === 0) return;
       const infoItem = itemList.filter((data:any)=>data.order_code === item);
       if(infoItem.length === 0) return;
       const discount2 = infoItem[0].plan_name.plan_price * 0.25;
       const planPrice = infoItem[0].discount_type === '1' ? (infoItem[0].plan_name?.plan_price ?? 0) : discountPlanRes(infoItem[0].plan_name?.plan_price ?? 0)
       
       setInfo({...infoItem[0],discount2,planPrice});
    },[item,itemList]);

    useEffect(()=>{
        if(info === undefined) return;
        getCardList();
    },[info?.telco_id,info?.order_id])

    const getList = async() =>{
        const res = await apiGetPhOrder(userInfo.jwt_token);
        if(res.result){
            setItemList(res.data);
            setItem(res.data[0].order_code);
        }
    }

    const getCardList = async() =>{
        const res = await apiGetDiscount('1',info.telco_id);
        
        if(res.result){
            const cardList = res.data.card_list;
            let card:any = undefined;
            cardList.map((data:any)=>{
                data.dis_card_list.map((item:any)=>{
                    if(item.dis_card_id === info.dis_card_id){
                        card = item;
                    }
                })
            })
            setCardDiscount((card?.dis_card_month_count ?? 0) * (card?.dis_card_month_price ?? 0));
        }
        
    }

    return (
        <Wrap>
            <SelectWrap>
                <SelectInnerWrap>
                    {itemList === undefined || itemList.length === 0 ?
                        <></> :
                        <SelectItem value={item} setValue={setItem} none='none'>
                            {itemList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`mypage-item-${index}`} value={String(data?.order_code)}>{data?.phone_info?.phone_model}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </SelectInnerWrap>
            </SelectWrap>
            <StepWrap>
                <MyPageStepItem info={info} />
            </StepWrap>
            <ContentWrap>
                <LeftWrap>
                    <RegisterPhoneInfoPart type='myPage' info={info} />
                </LeftWrap>
                <RightWrap>
                    <RegisterPayPart info={info} cardDiscount={cardDiscount} />
                    <BtnWrap onClick={()=>navigate('/inquiryCreate')}>문의하기</BtnWrap>
                </RightWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const SelectWrap = styled(Row)`
    justify-content: end;
    margin-top: 60px;
`;

const SelectInnerWrap = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-width: 195px;
`;

const StepWrap = styled.div`
    margin-top: 10px;
`;

const ContentWrap = styled(Row)`
    align-items: start;
    margin-top: 50px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 40px 0 ;
    }
`;

const LeftWrap = styled.div`
    width: 35%;
    padding: 10px 20px;
    @media ${addTheme.media.m}{
        width: 100%;
        padding: 0;
    }
`;
const RightWrap = styled.div`
    width: 65%;
    text-align: center;
    padding: 10px 20px;
    @media ${addTheme.media.m}{
        width: 100%;
        padding: 0;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;
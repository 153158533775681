import React from 'react'
import styled from 'styled-components'

// type01
// import PhoneBannerPart from '../../type01/phone/part/PhoneBannerPart';
// import PhoneListPart from '../../type01/phone/part/PhoneListPart';
// import PhoneReviewPart from '../../type01/phone/part/PhoneReviewPart';

// type02
import PhoneBannerPart from '../../type02/phone/part/PhoneBannerPart';
import PhoneListPart from '../../type02/phone/part/PhoneListPart';
import PhoneUserListPart from '../../type02/phone/part/PhoneUserListPart';
import PhoneReviewPart from '../../type02/phone/part/PhoneReviewPart';
import PhoneBtnPart from '../../type02/phone/part/PhoneBtnPart';

export default function PhonePage() {
    return (
        <Wrap>
            <PhoneBannerPart />
            <PhoneListPart />
            <PhoneUserListPart />
            <PhoneReviewPart />
            <PhoneBtnPart /> 
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 0px 0 50px  ;
`;